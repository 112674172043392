import React from "react"
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import About from "../components/about.js"

export default () => (
    <div>
        <Header/>
        <About/>
        <Footer />
    </div>
)