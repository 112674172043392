import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import SEO from "./seo.js"
import "./about.css";
import { FaHourglassHalf } from "react-icons/fa";

export default () => (
    <div className="about-content">

        <SEO
            title="Who is Raywon?"
            description="Who is Raywon?"
            pathname="/about"
        />

        <Container>
            <Row>
                <Col>
                    <h1>About Me</h1>
                    <br/>
                    <br/>
                    <h5>Just another tech geek... but rare to find... </h5>
                    <h5>Trying to be a better individual than yesterday...</h5>
                    <br/>
                    <h5>I want to make this tech world, a better place to live in... </h5>
                    <h5>Hope we cross paths in real world, so we can do it together...</h5>
                    <br/>
                    <h5>To know more about my skillset, checkout my Linkedin page...</h5>
                    <br/>
                    <p>-------------------------------------------</p>
                    <h6>.    This page is still under construction        <FaHourglassHalf size={30}/>.</h6>
                    <p>-------------------------------------------</p>
                </Col>
            </Row>
        </Container>

    </div>
)